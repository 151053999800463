var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

jQuery(document).ready(function () {

    jQuery('.toggle-navigation').on('click', function (event) {
        jQuery('body').toggleClass('navigation-open');
        event.preventDefault();
    });

    jQuery('.instagram-feed .feed a').magnificPopup({
        type: 'image',
    });

    jQuery('[data-youtube-video="true"]').magnificPopup({
        type: 'iframe',
    });

    if (jQuery('#inspiration-gallery').length) {
        jQuery('.inspiration-gallery').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 0,
            prevArrow: '<button type="button" class="slide-arrow slide-prev"><img src="/wp-content/themes/mensch-new/dist/images/left-arrow.png" alt=""></button>',
            nextArrow: '<button type="button" class="slide-arrow slide-next"><img src="/wp-content/themes/mensch-new/dist/images/right-arrow.png" alt=""></button>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
    if (jQuery('.woocommerce-product-gallery__wrapper').length) {
        jQuery('.woocommerce-product-gallery__wrapper').magnificPopup({
            delegate: 'a', // child items selector, by clicking on it popup will open
            type: 'image',
            gallery:{enabled:true}
            // other options
        });
    }

    var hash = window.location.hash;
    if (hash !== '') {
        if (jQuery(hash).length) {
            jQuery('html, body').animate({
                scrollTop: jQuery(hash).offset().top - headerOffset
            }, 1500);
        }
    }

    var $header = jQuery('header');
    var scrollTopOffset = 0;

    jQuery(window).on('load', function () {

    });

    jQuery(window).on('scroll', function () {
        scrollTopOffset = jQuery(window).scrollTop();

        if (scrollTopOffset > 300) {
            if (!$header.hasClass('selling-obscured')) {
                $header.addClass('selling-obscured');
            }
        } else {
            if ($header.hasClass('selling-obscured')) {
                $header.removeClass('selling-obscured');
            }
        }
    });

    jQuery(window).on('resize', function () {
        windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    });
});